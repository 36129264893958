import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import * as css from "@components/content.module.css"

function Sponsors() {
  return (
    <Layout>
      <SEO title="Sponsorzy - ZSP Nidek" />
      <h1>Sponsorzy</h1>
      <q lang="pl" style={{ textAlign: "right" }}>
        <em>Wszystko co wielkie, jest wielkie przez serce</em>
      </q>
      <em style={{ textAlign: "right", margin: "5px 0 20px 0" }}>
        Cyprian Kamil Norwid
      </em>
      <p>Państwu:</p>
      <div className={css.text}>
        <p>Krzysztofowi Nogali</p>
        <p>Firmie „Elen”</p>
        <p>Stanisławowi Kasperkowi</p>
        <p>Edwardowi Hojnemu</p>
        <p>Januszowi Mikule</p>
      </div>
      <p className={css.text}>
        za pracę na rzecz Zespołu Szkolno Przedszkolnego w Nidku serdeczne
        wyrazy podziękowania składają Dyrektor Zespołu, Grono Pedagogiczne oraz
        uczniowie.
      </p>
      <p>
        Głęboko wierzymy, że okazana przez Państwa siła serca i przyjazna dłoń,
        będzie nas wspierać w dalszej pracy podejmowanej dla dobra naszych
        uczniów i wychowanków.
      </p>
    </Layout>
  )
}

export default Sponsors
